import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function createData(
  date: string,
  unit: string,
  notes: string,
) {
  return { date, unit, notes};
}



export default function MaintenanceTable() {

  const rows = [
    createData('10/25/2023', '1003', 'AC: HEIM: Capacitor replaced. Contact HEIM for Warranty information within 12 months.'),
    createData('10/25/2023', '1021', 'Refrigerator: Replaced door handle.'),
    createData('10/24/2023', '1235', 'Water Heater: J&S Plumbing: Repaired water heater TPR valve. Contact J&S Plumbing for details on 12 months P&S warranty.'),
    createData('10/24/2023', '1132', 'Snaked toilet to unclog drain'),
    createData('10/22/2023', '1143', 'AC: Air conditioner blowing warm air.'),
    createData('10/20/2023', '1025', "Plumbing: Tony's Plumbing replaced diverter valve and shower cartridge. contact Tony's for details on 12 month P&S warranty."),
    createData('10/18/2023', '1310', 'AC: Bonney: Recharged Freon (R-22)'),
    createData('10/17/2023', '1124', 'Furnace: Replaced thermostat. Repaired wires.'),
    createData('10/15/2023', '1001', 'Toilet leaking. Wax ring replaced.'),
  ];
  return (
    <TableContainer sx={{ height: 300, overflow: 'auto' }} >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{fontSize: 20, fontWeight: 500}}>Date</TableCell>
            <TableCell align="center" style={{fontSize: 20, fontWeight: 500}}>Unit</TableCell>
            <TableCell align="center" style={{fontSize: 20, fontWeight: 500}}>Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.unit + row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.date}
              </TableCell>
              <TableCell align="center">{row.unit}</TableCell>
              <TableCell align="center">{row.notes}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}