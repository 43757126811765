import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Close } from '@mui/icons-material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  height: '90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
  overflow: 'scroll'
};

function createData(
    unit: string,
    warrantyType: string,
    capitalAsset: string,
    warrantyLength: string,
    warrantyEndDate: string,
  ) {
    return { unit, warrantyType, capitalAsset, warrantyLength, warrantyEndDate};
  }

const warrantyRows = [
    createData('107', 'Parts & Service', 'Air Conditioner', '1 Year', '09/12/2024'),
    createData('110', 'Manufacturer', 'Water Heater', '3 Years', '02/01/2026'),
    createData('113', 'Parts & Service', 'Plumbing Fixtures', '1 Year', '02/12/2024'),
    createData('120', 'Parts & Service', 'Water Heater', '1 Year', '01/01/2024'),
    createData('124', 'Parts & Service', 'Furnace', '1 Year', '04/12/2024'),
    createData('131', 'Manufacturer', 'Refrigerator', '4 Years', '02/01/2026'),
    createData('135', 'Parts & Service', 'Air Conditioner', '1 Year', '01/29/2024'),
    createData('137', 'Manufacturer', 'Air Conditioner', '5 Years', '02/01/2025'),
    createData('138', 'Manufacturer', 'Garbage Disposal', '5 Years', '02/12/2026'),
    createData('140', 'Manufacturer', 'Electrical Breaker', '5 Years', '02/01/2026'),
    createData('146', 'Manufacturer', 'Garbage Disposal', '5 Years', '02/12/2026'),
    createData('149', 'Manufacturer', 'Water Heater', '3 Years', '03/01/2025'),
    createData('150', 'Parts & Service', 'Furnace', '1 Year', '04/12/2024'),
  ];


export default function WarrantyModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen} style={{backgroundColor: '#637FFC', padding: 8, textDecoration:'none', color: 'white'}}>Warranty Report</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Grid xs={12} container >
                <Grid> 
                    <Button
                    onClick={()=>handleClose()}>
                    <Close /> 
                    </Button>
                    
                </Grid>
            </Grid>

          <Grid xs={12} container style={{ textAlign: 'center'}}>
            <Grid item xs={12} style={{fontSize: 50}}>Community Warranty Report</Grid>

          </Grid>

          <Grid container>
            <Grid xs={5} style={{ marginTop: 50, padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
                    <Grid item xs={8} style={{textAlign: 'left', fontSize: 30, fontWeight: 500, color: "#637FFC", paddingBottom: 5}}>
                    13
                    </Grid>
                    <Grid item xs={8} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649"}}>
                    Active Warranties
                    </Grid>
            </Grid>

            <Grid xs={2} />

            <Grid xs={5} style={{ marginTop: 50, padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
                    <Grid item xs={8} style={{textAlign: 'left', fontSize: 30, fontWeight: 500, color: "#637FFC", paddingBottom: 5}}>
                    6
                    </Grid>
                    <Grid item xs={8} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649"}}>
                    Parts & Service Warranties
                    </Grid>
            </Grid>


            <Grid xs={5} style={{ marginTop: 50, padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
                    <Grid item xs={8} style={{textAlign: 'left', fontSize: 30, fontWeight: 500, color: "#637FFC", paddingBottom: 5}}>
                    7
                    </Grid>
                    <Grid item xs={8} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649"}}>
                    Manufacturer Warranties
                    </Grid>
            </Grid>

            <Grid xs={2} />

            <Grid xs={5} style={{ marginTop: 50, padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
                    <Grid item xs={8} style={{textAlign: 'left', fontSize: 30, fontWeight: 500, color: "#637FFC", paddingBottom: 5}}>
                    6
                    </Grid>
                    <Grid item xs={8} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649"}}>
                    Work Orders on Assets Under Warranty
                    </Grid>
            </Grid>

            <Grid xs={12} container style={{ textAlign: 'center', marginTop: 40}}>
                <Grid item xs={12} style={{fontSize: 25, padding: 10, marginBottom: 20}}>Active Warranties</Grid>

                    {/* Washer */}                       
                    <Grid container style={{paddingBottom: 12}}>               
                            <TableContainer sx={{ height: 500, overflow: 'auto' }} >
                                <Table aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontSize: 14, fontWeight: 500}}>Unit</TableCell>
                                        <TableCell align="center" style={{fontSize: 14, fontWeight: 500}}>Warranty Type</TableCell>
                                        <TableCell align="center" style={{fontSize: 14, fontWeight: 500}}>Capital Asset</TableCell>
                                        <TableCell align="center" style={{fontSize: 14, fontWeight: 500}}>Warranty Length</TableCell>
                                        <TableCell align="center" style={{fontSize: 14, fontWeight: 500}}>Warranty End Date</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {warrantyRows.map((row) => (
                                        <TableRow
                                        key={row.unit}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {row.unit}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.warrantyType}
                                        </TableCell>
                                        <TableCell align="center">{row.capitalAsset}</TableCell>
                                        <TableCell align="center">{row.warrantyLength}</TableCell>
                                        <TableCell align="center">{row.warrantyEndDate}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                        </Grid>
                    </Grid>
            </Grid>

        </Box>
      </Modal>
    </div>
  );
}
