import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function createData(
  date: string,
  notes: string,
) {
  return { date, notes};
}



export default function SingleMaintenanceTable(data: any) {

  const rows = data.data.map((item:any)=>{
    return createData(item.date, item.notes)
  })


  return (
    <TableContainer style={{ height: '300px' }}>
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell style={{fontSize: 20, fontWeight: 500}}>Date</TableCell>
            <TableCell align="left" style={{fontSize: 20, fontWeight: 500}}>Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <TableRow
            key={row.date + row.notes} // Corrected from row.note to row.notes
            sx={{ '&:last-child td, &::last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" style={{fontSize: 12}}>
                {row.date}
              </TableCell>
              <TableCell style={{fontSize: 12}} align="left">{row.notes}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}