import './App.css';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { TimestampChart } from './TimestampChart';
import React from 'react';
import { CapitalAssetsPieChart } from './CapitalAssetsPieChart';
import MaintenanceTable from './MaintenanceTable';
import { maintenanceData } from './maintenancedata';
import SingleMaintenanceTable from './SingleUnitMaintenance';
import ApplianceTable from './CapitalAssetTable';
import { capitalAssets } from './capasset';
import TurnoverReportModal from './TurnoverReport';
import WarrantyModal from './WarrantyReport';
import { WorkOrderChart } from './WorkOrderChart';



const workOrderData = [
  {
      id: 'Work Orders',
      "color": "hsl(87, 70%, 50%)",
      data: [
          { x: '2022-01-01', y: 240 },
          { x: '2022-02-01', y: 250 },
          { x: '2022-03-01', y: 170 },
          { x: '2022-04-01', y: 230 },
          { x: '2022-05-01', y: 240 },
          { x: '2022-06-01', y: 250 },
          { x: '2022-07-01', y: 178 },
          { x: '2022-08-01', y: 200 },
          { x: '2022-09-01', y: 190 },
          { x: '2022-10-01', y: 186 },
          { x: '2022-11-01', y: 193 },
          { x: '2022-12-01', y: 182 },
          { x: '2023-01-01', y: 165 },
          { x: '2023-02-01', y: 161 },
          { x: '2023-03-01', y: 156 },
          { x: '2023-04-01', y: 166 },
          { x: '2023-05-01', y: 143 },
          { x: '2023-06-01', y: 120 },
          { x: '2023-07-01', y: 117 },
          { x: '2023-08-01', y: 140 },
          { x: '2023-09-01', y: 124 },
          { x: '2023-10-01', y: 110 },
      ],
  },
]

const data = {
  'All': [
  {
    "month": "Jan",
    "scans": 52,
    "scansColor": "hsl(283, 70%, 50%)",
  },
  {
    "month": "Feb",
    "scans": 25,
    "scansColor": "hsl(118, 70%, 50%)",
  },
  {
    "month": "Mar",
    "scans": 53,
    "scansColor": "hsl(216, 70%, 50%)",
  },
  {
    "month": "Apr",
    "scans": 105,
    "scansColor": "hsl(189, 70%, 50%)",
  },
  {
    "month": "May",
    "scans": 155,
    "scansColor": "hsl(101, 70%, 50%)",
  },
  {
    "month": "Jun",
    "scans": 192,
    "scansColor": "hsl(144, 70%, 50%)",
  },
  {
    "month": "Jul",
    "scans": 85,
    "scansColor": "hsl(253, 70%, 50%)",
  },
  {
    "month": "Aug",
    "scans": 121,
    "scansColor": "hsl(253, 70%, 50%)",
  },
  {
    "month": "Sep",
    "scans": 135,
    "scansColor": "hsl(253, 70%, 50%)",
  },
  {
    "month": "Oct",
    "scans": 79,
    "scansColor": "hsl(253, 70%, 50%)",
  }
],
'101':[
  {
    "month": "January",
    "scans": 22,
    "scansColor": "hsl(283, 70%, 50%)",
  },
  {
    "month": "February",
    "scans": 9,
    "scansColor": "hsl(118, 70%, 50%)",
  },
  {
    "month": "March",
    "scans": 26,
    "scansColor": "hsl(216, 70%, 50%)",
  },
  {
    "month": "April",
    "scans": 46,
    "scansColor": "hsl(189, 70%, 50%)",
  },
  {
    "month": "May",
    "scans": 36,
    "scansColor": "hsl(101, 70%, 50%)",
  },
  {
    "month": "June",
    "scans": 74,
    "scansColor": "hsl(144, 70%, 50%)",
  },
  {
    "month": "July",
    "scans": 25,
    "scansColor": "hsl(253, 70%, 50%)",
  },
  {
    "month": "August",
    "scans": 21,
    "scansColor": "hsl(253, 70%, 50%)",
  },
  {
    "month": "September",
    "scans": 68,
    "scansColor": "hsl(253, 70%, 50%)",
  },
  {
    "month": "October",
    "scans": 31,
    "scansColor": "hsl(253, 70%, 50%)",
  }
],
'102':[
  {
    "month": "January",
    "scans": 16,
    "scansColor": "hsl(283, 70%, 50%)",
  },
  {
    "month": "February",
    "scans": 7,
    "scansColor": "hsl(118, 70%, 50%)",
  },
  {
    "month": "March",
    "scans": 12,
    "scansColor": "hsl(216, 70%, 50%)",
  },
  {
    "month": "April",
    "scans": 39,
    "scansColor": "hsl(189, 70%, 50%)",
  },
  {
    "month": "May",
    "scans": 88,
    "scansColor": "hsl(101, 70%, 50%)",
  },
  {
    "month": "June",
    "scans": 18,
    "scansColor": "hsl(144, 70%, 50%)",
  },
  {
    "month": "July",
    "scans": 40,
    "scansColor": "hsl(253, 70%, 50%)",
  },
  {
    "month": "August",
    "scans": 24,
    "scansColor": "hsl(253, 70%, 50%)",
  },
  {
    "month": "September",
    "scans": 41,
    "scansColor": "hsl(253, 70%, 50%)",
  },
  {
    "month": "October",
    "scans": 32,
    "scansColor": "hsl(253, 70%, 50%)",
  }
],
'103':[
  {
    "month": "January",
    "scans": 14,
    "scansColor": "hsl(283, 70%, 50%)",
  },
  {
    "month": "February",
    "scans": 9,
    "scansColor": "hsl(118, 70%, 50%)",
  },
  {
    "month": "March",
    "scans": 15,
    "scansColor": "hsl(216, 70%, 50%)",
  },
  {
    "month": "April",
    "scans": 20,
    "scansColor": "hsl(189, 70%, 50%)",
  },
  {
    "month": "May",
    "scans": 31,
    "scansColor": "hsl(101, 70%, 50%)",
  },
  {
    "month": "June",
    "scans": 134,
    "scansColor": "hsl(144, 70%, 50%)",
  },
  {
    "month": "July",
    "scans": 20,
    "scansColor": "hsl(253, 70%, 50%)",
  },
  {
    "month": "August",
    "scans": 76,
    "scansColor": "hsl(253, 70%, 50%)",
  },
  {
    "month": "September",
    "scans": 26,
    "scansColor": "hsl(253, 70%, 50%)",
  },
  {
    "month": "October",
    "scans": 16,
    "scansColor": "hsl(253, 70%, 50%)",
  }
],
}


const completedSerialPlateData= [
  {
    "id": "Not Started",
    "label": "Not Started",
    "value": 97,
    "color": "hsl(71, 70%, 50%)"
  },
  {
    "id": "Completed Units",
    "label": "Completed",
    "value": 45,
    "color": "hsl(136, 70%, 50%)"
  },
  {
    "id": "Partial Completed Units",
    "label": "Partial",
    "value": 313,
    "color": "hsl(344, 70%, 50%)"
  },
]

const HVACAgeData= [
  {
    "id": "Less Than 8 Yrs",
    "label": "<8 Yrs",
    "value": 15.6,
    "color": "hsl(71, 70%, 50%)"
  },
  {
    "id": "8-15 Yrs",
    "label": "8-15 Yrs",
    "value": 25,
    "color": "hsl(136, 70%, 50%)"
  },
  {
    "id": "Greater Than 15 Yrs",
    "label": ">15 Yrs",
    "value": 59.4,
    "color": "hsl(344, 70%, 50%)"
  },
]

const FurnaceAgeData= [
  {
    "id": "Less Than 8 Yrs",
    "label": "<8 Yrs",
    "value": 22.6,
    "color": "hsl(71, 70%, 50%)"
  },
  {
    "id": "8-15 Yrs",
    "label": "8-15 Yrs",
    "value": 29,
    "color": "hsl(136, 70%, 50%)"
  },
  {
    "id": "Greater Than 15 Yrs",
    "label": ">15 Yrs",
    "value": 48.4,
    "color": "hsl(344, 70%, 50%)"
  },
]

const WaterHeaterAgeData = [
  {
    "id": "Less Than 8 Yrs",
    "label": "<8 Yrs",
    "value": 63.3,
    "color": "hsl(71, 70%, 50%)"
  },
  {
    "id": "8-15 Yrs",
    "label": "8-15 Yrs",
    "value": 9.5,
    "color": "hsl(136, 70%, 50%)"
  },
  {
    "id": "Greater Than 15 Yrs",
    "label": ">15 Yrs",
    "value": 27.2,
    "color": "hsl(344, 70%, 50%)"
  },
]


function App() {

  const [unitNumber, setUnitNumber] = React.useState('All');
  const [chartData, setChartData] = React.useState(data.All)
  const [unitNumberMaint, setUnitNumberMaint] = React.useState('1001');
  console.log(setUnitNumberMaint)
  const [maintChartData, setMaintChartData] = React.useState(maintenanceData[1001]);

 const changeUnit = (e:any)=>{
      switch (e.target.value) {
        case '1001': {
          setChartData(data[101])
          setUnitNumber('1001')
          return
        } 

        case '1002': {
          setChartData(data[102])
          setUnitNumber('1002')
          return 
        } 

        case '1003': {
          setChartData(data[103])
          setUnitNumber('1003')
          return 
        } 

        case 'All': {
          setChartData(data.All)
          setUnitNumber('All')
          return 
        } 

        default:
          throw Error()
      }
  }


  const changeUnitMaint = (e:any)=>{
    switch (e.target.value) {
      case '1001': {
        setMaintChartData(maintenanceData[1001])
        setUnitNumberMaint('1001')
        return
      } 

      case '1002': {
        setMaintChartData(maintenanceData[1002])
        setUnitNumberMaint('1002')
        return 
      } 

      case '1003': {
        setMaintChartData(maintenanceData[1003])
        setUnitNumberMaint('1003')
        return 
      } 

      case '1004': {
        setMaintChartData(maintenanceData[1004])
        setUnitNumberMaint('1004')
        return 
      } 

      default:
        throw Error()
    }
}


  return (
    <div className="App" style={{ backgroundColor: "#f7faff"}}>
      <Grid container xs={12} px={12}>
        <Grid xs={2}>
          <img alt="" style={{height: 150}} src="/OAILogoCropped.png"></img>
        </Grid>
        <Grid xs={1}></Grid>
        <Grid xs={6} style={{height: 150}} py={5}>
            <Grid xs={12} style={{fontSize: 50}}>
            Lakefront Apartments
            </Grid>
            <Grid xs={12}>
            123 Main Street, Citrus Heights, CA 
            </Grid>
            <Grid xs={12}>
            344 Units
            </Grid>
        </Grid>
        <Grid xs={3}></Grid>


          {/* <Grid xs={4} style={{ padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>

          </Grid>
          <Grid xs={1}></Grid> */}

          <Grid item style={{marginTop: 20, alignItems: 'left'}}>
              <TurnoverReportModal />
            </Grid>
            <Grid item xs={3}style={{marginTop: 20}}>
              <WarrantyModal />
          </Grid>

          <Grid xs={12} style={{ marginTop: 50, padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
            <Grid container>
              <Grid item xs={8} style={{textAlign: 'left', fontSize: 30, fontWeight: 500, color: "#313649"}}>
                Work Orders
              </Grid>
              <Grid style={{height:500, width: '100%'}}>
                <WorkOrderChart data={workOrderData} />
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={12} style={{ marginTop: 50, padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
            
            <Grid container>
            <Grid item xs={8} style={{textAlign: 'left', fontSize: 30, fontWeight: 500, color: "#313649"}}>
              Timestamp Scan Data
            </Grid>
            <Grid xs={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={unitNumber}
                  label="Unit"
                  onChange={changeUnit}
                >
                  <MenuItem value={'All'}>All</MenuItem>
                  <MenuItem value={'1001'}>1001</MenuItem>
                  <MenuItem value={'1002'}>1002</MenuItem>
                  <MenuItem value={'1003'}>1003</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            </Grid>
            
            <Grid style={{height: "80%"}}>
              <TimestampChart data={chartData} />
            </Grid>
          </Grid>

        <Grid container xs={12} py={5}>
          <Grid xs={12} style={{textAlign: 'left', fontSize: 40, fontWeight: 500, color: "#313649", paddingBottom: 30}}>
              Maintenance
          </Grid>
          
          <Grid xs={6} style={{ padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
            <Grid xs={12} style={{textAlign: 'left', fontSize: 20, fontWeight: 500, color: "#313649"}}>
              Recent Maintenance
            </Grid>
            <Grid xs={12}>
              <MaintenanceTable />
            </Grid>
          </Grid>

          <Grid xs={2}></Grid>

          <Grid xs={4} container style={{ padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
            <Grid xs={8} style={{textAlign: 'left', fontSize: 20, fontWeight: 500, color: "#313649"}}>
              Maintenance By Unit
            </Grid>
            <Grid xs={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={unitNumberMaint}
                  label="Unit"
                  onChange={changeUnitMaint}
                >
                  <MenuItem value={'1001'}>1001</MenuItem>
                  <MenuItem value={'1002'}>1002</MenuItem>
                  <MenuItem value={'1003'}>1003</MenuItem>
                  <MenuItem value={'1004'}>1004</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12}>
              <SingleMaintenanceTable data={maintChartData} />
            </Grid>
          </Grid>
        </Grid>
        
        <Grid container xs={12} py={5}>
          <Grid container xs={12}>
            <Grid xs={12} style={{textAlign: 'left', fontSize: 40, fontWeight: 500, color: "#313649"}}>
              Asset Tracking
            </Grid>
            <Grid container xs={12} style={{paddingTop: 30}}>
              <Grid xs={5} style={{ padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
                    <Grid  style={{textAlign: 'left', fontSize: 20, fontWeight: 500, color: "#313649"}}>
                    Completed Serial Plate Photos
                    </Grid>
                    <Grid style={{ height: "90%"}}>
                      <CapitalAssetsPieChart data={completedSerialPlateData} />
                    </Grid>
              </Grid>
              <Grid xs={2}></Grid>

              <Grid xs={5} style={{ padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
                    <Grid  style={{textAlign: 'left', fontSize: 20, fontWeight: 500, color: "#313649"}}>
                      Air Conditioner Age Distribution
                    </Grid>
                    <Grid style={{ height: "90%"}}>
                      <CapitalAssetsPieChart data={HVACAgeData} />
                    </Grid>
              </Grid>
            </Grid>  

            <Grid container xs={12} style={{paddingTop: 30}}>
              <Grid xs={5} style={{ padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
                    <Grid  style={{textAlign: 'left', fontSize: 20, fontWeight: 500, color: "#313649"}}>
                    Furnace Age Distribution
                    </Grid>
                    <Grid style={{ height: "90%"}}>
                      <CapitalAssetsPieChart data={FurnaceAgeData} />
                    </Grid>
                    
              </Grid>

              <Grid xs={2}></Grid>

              <Grid xs={5} style={{ padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
                    <Grid  style={{textAlign: 'left', fontSize: 20, fontWeight: 500, color: "#313649"}}>
                    Water Heater Age Distribution
                    </Grid>
                    <Grid style={{ height: "90%"}}>
                      <CapitalAssetsPieChart data={WaterHeaterAgeData} />
                    </Grid> 
              </Grid>
            </Grid> 

            <Grid container xs={12}  py={5} style={{ backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25, paddingTop: 30, marginTop: 50}}>
              <Grid  style={{textAlign: 'left', fontSize: 20, fontWeight: 500, color: "#313649", paddingLeft: 30}}>
                Capital Asset Inventory
              </Grid>
              <Grid style={{padding: 30}}>
                <ApplianceTable data={capitalAssets} />
              </Grid>
              
            </Grid>        
          </Grid>
        </Grid>

        
      </Grid>
  
    </div>
  );
}

export default App;
