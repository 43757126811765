import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FormControl, InputLabel, Select, MenuItem, Grid, TextField, SelectChangeEvent, Button } from '@mui/material';

interface Appliance {
    Make: string;
    Model: string;
    Age: number;
    "Warranty Status": string; // Notice the quotes and spaces to match your data's keys exactly.
  }
  
  interface Unit {
    "Unit Number": number;
    "Air Conditioner": Appliance;
    Furnace: Appliance;
    "Water Heater": Appliance;
    Refrigerator: Appliance;
    Range: Appliance;
    Dishwasher: Appliance;
    "Garbage Disposal": Appliance;
    Washer: Appliance;
    Dryer: Appliance;
  }

interface Props {
  data: { units: Unit[] };
}


const ApplianceTable: React.FC<Props> = ({ data }) => {
  const [selectedUnitNumber, setSelectedUnitNumber] = useState('');
  const [selectedApplianceType, setSelectedApplianceType] = useState('');
  const [ageFilter, setAgeFilter] = useState('');
  const [makeFilter, setMakeFilter] = useState('');

  const handleUnitChange = (event: SelectChangeEvent) => {
    setSelectedUnitNumber(event.target.value as string);
  };
  
  const handleApplianceTypeChange = (event: SelectChangeEvent) => {
    setSelectedApplianceType(event.target.value as string);
  };

  const handleAgeFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgeFilter(event.target.value);
  };

  const handleMakeFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMakeFilter(event.target.value);
  };

  const resetFilters = () => {
    setSelectedUnitNumber('');
    setSelectedApplianceType('');
    setAgeFilter('');
    setMakeFilter('');
  };

  const applyFilters = () => {
    return data.units
      .filter((unit) => !selectedUnitNumber || unit['Unit Number'].toString() === selectedUnitNumber)
      .flatMap((unit) => {
        // Get appliance entries only, excluding "Unit Number"
        return Object.entries(unit)
          .filter(([key, _]) => key !== 'Unit Number')
          .filter(([key, _]) => !selectedApplianceType || key === selectedApplianceType)
          .map(([key, appliance]) => ({
            unitNumber: unit['Unit Number'],
            applianceType: key,
            ...appliance,
          }))
      })
      .filter((item) => {
        // Apply the age and make filters
        const meetsAgeCriteria = !ageFilter || item.Age > parseInt(ageFilter, 10);
        const meetsMakeCriteria = !makeFilter || item.Make === makeFilter;
        return meetsAgeCriteria && meetsMakeCriteria;
      });
  };

  const filteredAppliances = applyFilters();

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} >
        {/* Unit Number Filter */}
        <Grid xs={5}>
            <FormControl fullWidth margin="normal">
            <InputLabel>Unit Number</InputLabel>
            <Select value={selectedUnitNumber} onChange={handleUnitChange} displayEmpty>
                <MenuItem value="">
                <em>All Units</em>
                </MenuItem>
                {data.units.map((unit) => (
                <MenuItem key={unit['Unit Number']} value={unit['Unit Number'].toString()}>
                    {unit['Unit Number']}
                </MenuItem>
                ))}
            </Select>
            </FormControl>
        </Grid>

        <Grid xs={2}></Grid>

        <Grid xs={5}>
        {/* Appliance Type Filter */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Appliance Type</InputLabel>
          <Select value={selectedApplianceType} onChange={handleApplianceTypeChange} displayEmpty>
            <MenuItem value="">
              <em>All Appliances</em>
            </MenuItem>
            {/* Populate with all appliance types from the data */}
            {Object.keys(data.units[0])
              .filter((key) => key !== 'Unit Number')
              .map((key, index) => (
                <MenuItem key={index} value={key}>
                  {key}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        </Grid>


        <Grid xs={5}>
            {/* Age Filter */}
            <TextField
            fullWidth
            margin="normal"
            label="Age Greater Than"
            type="number"
            value={ageFilter}
            onChange={handleAgeFilterChange}
            />
        </Grid>

        <Grid xs={2}></Grid>

        <Grid xs={5}>
            {/* Make Filter */}
            <TextField
            fullWidth
            margin="normal"
            label="Make"
            type="text"
            value={makeFilter}
            onChange={handleMakeFilterChange}
            />
        </Grid>
        
        <Grid xs={12}>
            <Button variant="contained" style={{backgroundColor: '#637FFC',width: '100%' }} onClick={resetFilters} sx={{ mt: 2, px: 3, py: 2 }}>
            Reset Filters
            </Button>
        </Grid>
        
      </Grid>

      <Grid item xs={12}>
        {/* Table to display the filtered appliances */}
        <TableContainer style={{ maxHeight: '500px' }}>
          <Table stickyHeader aria-label="Appliance table">
            <TableHead>
              <TableRow>
                <TableCell style={{fontSize: 20, fontWeight: 500}}>Unit Number</TableCell>
                <TableCell style={{fontSize: 20, fontWeight: 500}}>Appliance Type</TableCell>
                <TableCell style={{fontSize: 20, fontWeight: 500}}>Make</TableCell>
                <TableCell style={{fontSize: 20, fontWeight: 500}}>Model</TableCell>
                <TableCell style={{fontSize: 20, fontWeight: 500}}>Age</TableCell>
                <TableCell style={{fontSize: 20, fontWeight: 500}}>Warranty Status</TableCell>
                <TableCell style={{fontSize: 20, fontWeight: 500}}>Expected Lifespan</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
  {filteredAppliances.map((appliance, index) => (
    <TableRow key={index}>
      <TableCell>{appliance.unitNumber}</TableCell> 
      <TableCell>{appliance.applianceType}</TableCell>
      <TableCell>{appliance.Make}</TableCell>
      <TableCell>{appliance.Model}</TableCell>
      <TableCell>{appliance.Age}</TableCell>
      <TableCell>{appliance["Warranty Status"]}</TableCell>
      <TableCell>{15 - Number(appliance.Age)}</TableCell>
    </TableRow>
  ))}
</TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ApplianceTable;
