

export const allMaintenanceData = [
    {
        "date": "10/25/2023",
        "unit": "1003",
        "notes": "AC: HEIM: Capacitor replaced. Contact HEIM for Warranty information within 12 months."
    },
    {
        "date": "10/25/2023",
        "unit": "1921",
        "notes": "Refrigerator: Replaced door handle."
    },
    {
        "date": "10/24/2023",
        "unit": "1235",
        "notes": "Water Heater: J&S Plumbing: Repaired water heater TPR valve. Contact J&S Plumbing for details on 12 months P&S warranty."
    },
    {
        "date": "10/24/2023",
        "unit": "1132",
        "notes": "Snaked toilet to unclog drain"
    },
    {
        "date": "10/12/2023",
        "unit": "483",
        "notes": "AC: Air conditioner blowing warm air."
    },
    {
        "date": "10/11/2023",
        "unit": "214",
        "notes": "Plumbing: Tony's Plumbing replaced diverter valve and shower cartridge. contact Tony's for details on 12 month P&S warranty."
    },
    {
        "date": "10/05/2023",
        "unit": "1142",
        "notes": "Furnace: Replaced thermostat. Repaired wires."
    },
    {
        "date": "10/05/2023",
        "unit": "1142",
        "notes": "AC: Heim: Refilled Freon and repaired intake line."
    },
    {
        "date": "01/02/2023",
        "unit": "1999",
        "notes": "AC: Bonney: Recharged Freon (R-22)"
    },



]

export const maintenanceData = {
    1001: [
        {
            "date": "07/16/2023",
            "notes": "Touch up paint light clean regular carpet cleaning stove"
        },
        {
            "date": "04/11/2023",
            "notes": "repaired shower rod repaired towel bar replaced smoke Detector installed 10 slats"
        },
        {
            "date": "01/11/2023",
            "notes": "Unit Turn"
        },
        {
            "date": "11/19/2022",
            "notes": "Replaced mailbox lock"
        },
], 
1002: [
    {
        "date": "10/25/2023",
        "notes": "AC: HEIM: Capacitor replaced. Contact HEIM for Warranty information within 12 months."
    },
    {
        "date": "10/25/2023",
        "notes": "Refrigerator: Replaced door handle."
    },
    {
        "date": "10/24/2023",
        "notes": "Unit Turn"
    },
    {
        "date": "10/24/2023",
        "notes": "Snaked toilet to unclog drain"
    },
], 
1003: [
    {
        "date": "05/25/2023",
        "notes": "Slats replaced"
    },
    {
        "date": "03/11/2023",
        "notes": "Reset breaker"
    },
    {
        "date": "12/23/2022",
        "notes": "Unit Turn"
    },
    {
        "date": "10/02/2022",
        "notes": "Replaced 4 light bulbs"
    },
], 
1004: [
    {
        "date": "05/25/2023",
        "notes": "AC: Air conditioner blowing warm air."
    },
    {
        "date": "03/11/2023",
        "notes": "Plumbing: Tony's Plumbing replaced diverter valve and shower cartridge. contact Tony's for details on 12 month P&S warranty."
    },
    {
        "date": "12/23/2022",
        "notes": "AC: Bonney: Recharged Freon (R-22)"
    },
    {
        "date": "10/02/2022",
        "notes": "Furnace: Replaced thermostat. Repaired wires."
    },
], 
}