// const completedSerialPlate = {
//     "propertyId": 25,
//     "notStarted": 184,
//     "completed": 110,
//     "partiallyCompleted": 50 
// }

// const airConditionerAge = {
//     "propertyId": 25,
//     "<8": 15.6,
//     "8-15": 25,
//     ">15": 59.4
// }

export const capitalAssets = {
    "units": [
      {
        "Unit Number": 1001,
        "Air Conditioner": {
          "Make": "TRANE",
          "Model": "XV17",
          "Age": 14,
          "Warranty Status": "No"
        },
        "Furnace": {
          "Make": "TRANE",
          "Model": "XC95M",
          "Age": 2,
          "Warranty Status": "Yes"
        },
        "Water Heater": {
          "Make": "American Standard",
          "Model": "D-100 SD",
          "Age": 19,
          "Warranty Status": "No"
        },
        "Refrigerator": {
          "Make": "GE",
          "Model": "Sxs12-92",
          "Age": 11,
          "Warranty Status": "Yes"
        },
        "Range": {
          "Make": "GE",
          "Model": "TRC12-32",
          "Age": 3,
          "Warranty Status": "Yes"
        },
        "Dishwasher": {
          "Make": "GE",
          "Model": "DW32-XS",
          "Age": 2,
          "Warranty Status": "No"
        },
        "Garbage Disposal": {
          "Make": "InSinkerator",
          "Model": "56-220-240",
          "Age": 4,
          "Warranty Status": "Yes"
        },
        "Washer": {
          "Make": "Maytag",
          "Model": "MT34-201",
          "Age": 7,
          "Warranty Status": "No"
        },
        "Dryer": {
          "Make": "Maytag",
          "Model": "MT503-21",
          "Age": 4,
          "Warranty Status": "Yes"
        }
      },
      {
        "Unit Number": 1002,
        "Air Conditioner": {
          "Make": "TRANE",
          "Model": "XV17",
          "Age": 2,
          "Warranty Status": "Yes"
        },
        "Furnace": {
          "Make": "TRANE",
          "Model": "XC95M",
          "Age": 19,
          "Warranty Status": "No"
        },
        "Water Heater": {
          "Make": "American Standard",
          "Model": "D-100 SD",
          "Age": 4,
          "Warranty Status": "Yes"
        },
        "Refrigerator": {
          "Make": "GE",
          "Model": "Sxs12-92",
          "Age": 5,
          "Warranty Status": "No"
        },
        "Range": {
          "Make": "GE",
          "Model": "TRC12-32",
          "Age": 11,
          "Warranty Status": "Yes"
        },
        "Dishwasher": {
          "Make": "GE",
          "Model": "DW32-XS",
          "Age": 2,
          "Warranty Status": "Yes"
        },
        "Garbage Disposal": {
          "Make": "InSinkerator",
          "Model": "56-220-240",
          "Age": 6,
          "Warranty Status": "No"
        },
        "Washer": {
          "Make": "Maytag",
          "Model": "MT34-201",
          "Age": 3,
          "Warranty Status": "Yes"
        },
        "Dryer": {
          "Make": "Maytag",
          "Model": "MT503-21",
          "Age": 18,
          "Warranty Status": "No"
        }
      },
      {
        "Unit Number": 1003,
        "Air Conditioner": {
          "Make": "Lennox",
          "Model": "XC25",
          "Age": 2,
          "Warranty Status": "Yes"
        },
        "Furnace": {
          "Make": "Lennox",
          "Model": "SLP98V",
          "Age": 4,
          "Warranty Status": "No"
        },
        "Water Heater": {
          "Make": "Rheem",
          "Model": "Performance Plus",
          "Age": 11,
          "Warranty Status": "Yes"
        },
        "Refrigerator": {
          "Make": "Samsung",
          "Model": "RF28R7351SG",
          "Age": 1,
          "Warranty Status": "Yes"
        },
        "Range": {
          "Make": "Whirlpool",
          "Model": "WFE515S0ES",
          "Age": 2,
          "Warranty Status": "No"
        },
        "Dishwasher": {
          "Make": "Bosch",
          "Model": "SHEM63W55N",
          "Age": 1,
          "Warranty Status": "Yes"
        },
        "Garbage Disposal": {
          "Make": "Moen",
          "Model": "GX50C",
          "Age": 2,
          "Warranty Status": "Yes"
        },
        "Washer": {
          "Make": "LG",
          "Model": "WM3500CW",
          "Age": 3,
          "Warranty Status": "No"
        },
        "Dryer": {
          "Make": "LG",
          "Model": "DLE3500W",
          "Age": 2,
          "Warranty Status": "Yes"
        }
      },
      {
        "Unit Number": 1004,
        "Air Conditioner": {
          "Make": "Carrier",
          "Model": "Infinity 26",
          "Age": 3,
          "Warranty Status": "Yes"
        },
        "Furnace": {
          "Make": "Carrier",
          "Model": "Infinity 98",
          "Age": 13,
          "Warranty Status": "No"
        },
        "Water Heater": {
          "Make": "A.O. Smith",
          "Model": "Signature Premier",
          "Age": 20,
          "Warranty Status": "No"
        },
        "Refrigerator": {
          "Make": "LG",
          "Model": "LFXS28968S",
          "Age": 5,
          "Warranty Status": "Yes"
        },
        "Range": {
          "Make": "KitchenAid",
          "Model": "KSGB900ESS",
          "Age": 3,
          "Warranty Status": "No"
        },
        "Dishwasher": {
          "Make": "KitchenAid",
          "Model": "KDPE234GPS",
          "Age": 2,
          "Warranty Status": "Yes"
        },
        "Garbage Disposal": {
          "Make": "Waste King",
          "Model": "L-8000",
          "Age": 7,
          "Warranty Status": "Yes"
        },
        "Washer": {
          "Make": "Maytag",
          "Model": "MVW7232HW",
          "Age": 11,
          "Warranty Status": "No"
        },
        "Dryer": {
          "Make": "Maytag",
          "Model": "MED7230HW",
          "Age": 3,
          "Warranty Status": "Yes"
        }
      },
      {
        "Unit Number": 1005,
        "Air Conditioner": {
          "Make": "York",
          "Model": "Affinity YXV",
          "Age": 2,
          "Warranty Status": "No"
        },
        "Furnace": {
          "Make": "York",
          "Model": "TM9V",
          "Age": 3,
          "Warranty Status": "Yes"
        },
        "Water Heater": {
          "Make": "Bradford White",
          "Model": "RG240T6N",
          "Age": 11,
          "Warranty Status": "Yes"
        },
        "Refrigerator": {
          "Make": "Frigidaire",
          "Model": "FFSS2615TS",
          "Age": 8,
          "Warranty Status": "No"
        },
        "Range": {
          "Make": "Electrolux",
          "Model": "EI30EF45QS",
          "Age": 4,
          "Warranty Status": "Yes"
        },
        "Dishwasher": {
          "Make": "Whirlpool",
          "Model": "WDF520PADM",
          "Age": 3,
          "Warranty Status": "No"
        },
        "Garbage Disposal": {
          "Make": "Insinkerator",
          "Model": "Evolution Excel",
          "Age": 2,
          "Warranty Status": "Yes"
        },
        "Washer": {
          "Make": "Kenmore",
          "Model": "26132",
          "Age": 8,
          "Warranty Status": "No"
        },
        "Dryer": {
          "Make": "Kenmore",
          "Model": "67132",
          "Age": 3,
          "Warranty Status": "Yes"
        }
      }
    ]
  }
  