// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/line
import { ResponsiveLine } from '@nivo/line'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const WorkOrderChart = ({ data }: {data: any} /* see data tab */  ) => (
    <ResponsiveLine
        xScale={{
            type: "time",
            format: "%Y-%m-%d"
        }}
        xFormat="time:%Y-%m-%d"
        data={data}
        margin={{ top: 50, right: 160, bottom: 50, left: 60 }}
        // xScale={{ type: 'linear' }}
        yScale={{ type: 'linear', stacked: true, min: 0, max: 300 }}
        yFormat=" >-.2f"
        curve="monotoneX"
        axisTop={null}
        axisRight={{
            tickValues: [
                0,
                20,
                40,
                60,
                80,
                100,
                120,
                140,
                160,
                180,
                200,
                220,
                240,
                260
            ],
            tickSize: 1,
            tickPadding: 5,
            tickRotation: 0,
            format: '.2s',
            legend: '',
            legendOffset: 0
        }}
        axisBottom={{
            // tickValues: [
            //     '01-22',
            //     '02-22',
            //     3,
            //     4,
            //     5,
            //     6,
            //     7,
            //     8,
            //     9,
            //     10,
            //     11,
            //     12,
            //     13,
            //     14,
            //     15,
            //     16,
            //     17,
            //     18,
            //     19,
            //     20,
            //     21,
            //     22,
            // ],
            legendOffset: -12,
            format: "%b %y",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Month',
            // legendOffset: 36,
            legendPosition: 'middle'
        }}
        axisLeft={{
            tickValues: [
                0,
                20,
                40,
                60,
                80,
                100,
                120,
                140,
                160,
                180,
                200,
                220,
                240,
                260
            ],
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: '.2s',
            legend: 'Work Orders',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        enableGridX={false}
        colors={{ scheme: 'spectral' }}
        lineWidth={1}
        pointSize={4}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={1}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        gridXValues={[ 1,2,3,4,5,6,7,8,9,10,11,12 ]}
        gridYValues={[ 0,20,40,60,80,100,120,140,160,180,200,220,240,260]}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 140,
                translateY: 0,
                itemsSpacing: 2,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 12,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
)