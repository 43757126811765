import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Close, Description } from '@mui/icons-material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  height: '90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
  overflow: 'scroll'
};

function createData(
    date: string,
    notes: string,
  ) {
    return { date, notes};
  }

const ACrows = [
    createData('07/09/2022', 'Replaced blower motor'),
    createData('8/12/2023', 'Replaced capacitor'),
    createData('', 'Capacitor under parts and service warranty for 7 months.'),
  ];

const furnaceRows = [
    createData('2/14/2019', 'Repalced thermostat & repaired wires'),
];

const waterHeaterRows = [
    createData('09/10/2019', 'Unit drained and maintenanced'),
];

const dryerRows = [
    createData('10/10/2020', 'Filter replaced.'),
];

const refrigeratorRows = [
    createData('1/12/2021', 'Cleaned condenser coils.'),
    createData('', 'Tenant said that door handle is broken.'),
];

const dishwasherRows: {date:string; notes: string}[] = [
];

const washerRows: {date:string; notes: string}[] = [];

export default function TurnoverReportModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen} style={{backgroundColor: '#637FFC', padding: 8, textDecoration:'none', color: 'white'}}>Turnover Report</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            
            <Grid xs={12} container style={{position: 'absolute'}}>
                <Grid> 
                    <Button
                    onClick={()=>handleClose()}>
                    <Close /> 
                    </Button>
                    
                </Grid>
            </Grid>

          <Grid xs={12} container style={{ textAlign: 'center'}}>
            <Grid xs={12} style={{fontSize: 50}}>Turnover Report</Grid>
            <Grid xs={12} style={{fontSize: 20}}>Unit 107</Grid>
          </Grid>

          <Grid container>
            <Grid xs={5} style={{ marginTop: 50, padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
                    <Grid item xs={12} style={{textAlign: 'left', fontSize: 30, fontWeight: 500, color: "#637FFC", paddingBottom: 5}}>
                    13
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649"}}>
                    Work Orders in the last 24 months
                    </Grid>
            </Grid>

            <Grid xs={2} />

            <Grid xs={5} style={{ marginTop: 50, padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
                    <Grid item xs={12} style={{textAlign: 'left', fontSize: 30, fontWeight: 500, color: "#637FFC", paddingBottom: 5}}>
                    53%
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649"}}>
                    Of work orders related to plumbing fixtures and appliances 
                    </Grid>
            </Grid>


            <Grid xs={5} style={{ marginTop: 50, padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
                    <Grid item xs={12} style={{textAlign: 'left', fontSize: 30, fontWeight: 500, color: "#637FFC", paddingBottom: 5}}>
                    Information
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649"}}>
                    Low flow toilet must be installed according to Citrus Heights city ordinance 106.80
                    </Grid>
            </Grid>

            <Grid xs={2} />

            <Grid xs={5} style={{ marginTop: 50, padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
                    <Grid item xs={12} style={{textAlign: 'left', fontSize: 30, fontWeight: 500, color: "#637FFC", paddingBottom: 5}}>
                        1
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649"}}>
                    Capital Asset Under Warranty

                    </Grid>
            </Grid>

            <Grid xs={5} style={{ marginTop: 50, padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
                    <Grid item xs={12} style={{textAlign: 'left', fontSize: 30, fontWeight: 500, color: "#637FFC", paddingBottom: 5}}>
                        3 of 9
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649"}}>
                        Capital Asset at or past their estimated useful life.
                    </Grid>
            </Grid>

            <Grid xs={2} />

            <Grid xs={5} style={{ marginTop: 50, padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
                    <Grid item xs={8} style={{textAlign: 'left', fontSize: 30, fontWeight: 500, color: "#637FFC", paddingBottom: 5}}>
                        2
                    </Grid>
                    <Grid item xs={8} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649"}}>
                        Items identified as <b>Poor Condition</b> in most recent annual inspection
                    </Grid>
            </Grid>

            <Grid xs={12} container style={{ textAlign: 'center', marginTop: 40}}>
                <Grid item style={{fontSize: 30}} xs={12}>Notable Items</Grid>

                <Grid container style={{ marginTop: 50, padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
                    <Grid item xs={3} style={{textAlign:'center', alignContent:'center', alignItems:'center', color: '#637FFC'}} >
                        <Grid container justifyContent="center" alignItems="center" style={{height:'100%'}}>
                            <Description style={{fontSize: 100}}/>
                        </Grid>
                    </Grid>
                    
                    <Grid item xs={9}>
                        <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                            2023 Annual inspection indicated that the carpet was graded as a 2/5 in condition. 
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                            2023 Annual inspection indicated a hole in the dry wall of bedroom 2
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                            Tenant indicated a broken refrigerator door handle on 12/1/23
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                            Check air filter
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                            Water Heater is an estimated 10 years past its useful life
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                            Air Conditioner is under warranty
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Grid>

            <Grid xs={12} container style={{ textAlign: 'center', marginTop: 40}}>
                <Grid item xs={12} style={{fontSize: 30}}>24 Months Maintenance History</Grid>

                <Grid container style={{ marginTop: 50, padding: 30, backgroundColor: "white", color: "black", boxShadow: "0px 16px 15px rgb(112, 144, 176, .20)", border:"solid rgb(112, 144, 176, .20) 1px", borderRadius: 25}}>
                    {/*Air Conditioner */}
                    <Grid container style={{paddingBottom: 20, borderBottom: 'dashed #313649 1px', marginBottom: 20}}>
                        <Grid container xs={3} justifyContent="center" alignItems="center">
                            <Grid>
                                Air Conditioner
                            </Grid>  
                        </Grid>

                        <Grid item xs={9}>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Age:</b> 15
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Estimated Life Span:</b> 0
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                            <b>Last Work Order:</b> 08/12/2023
                            </Grid>
                            
                            <TableContainer sx={{ height: 100, overflow: 'auto' }} >
                                <Table aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontSize: 14, fontWeight: 500}}>Date</TableCell>
                                        <TableCell align="center" style={{fontSize: 14, fontWeight: 500}}>Notes</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {ACrows.map((row) => (
                                        <TableRow
                                        key={row.date}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {row.date}
                                        </TableCell>
                                        <TableCell align="center">{row.notes}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                        </Grid>
                    </Grid>

                     {/*Furnace */}                       
                    <Grid container style={{paddingBottom: 10, borderBottom: 'dashed #313649 1px', marginBottom: 20}}>
                        <Grid container xs={3} justifyContent="center" alignItems="center">
                            <Grid>
                                Furnace
                            </Grid>  
                        </Grid>
                        <Grid item xs={9}>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Age:</b> 15
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Estimated Life Span:</b> 0
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Last Work Order:</b> 02/14/2019
                            </Grid>
                            
                            <TableContainer sx={{ height: 100, overflow: 'auto' }} >
                                <Table aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontSize: 14, fontWeight: 500}}>Date</TableCell>
                                        <TableCell align="center" style={{fontSize: 14, fontWeight: 500}}>Notes</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {furnaceRows.map((row) => (
                                        <TableRow
                                        key={row.date}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {row.date}
                                        </TableCell>
                                        <TableCell align="center">{row.notes}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                        </Grid>
                    </Grid>


                    {/* Water Heater */}                       
                    <Grid container style={{paddingBottom: 10, borderBottom: 'dashed #313649 1px', marginBottom: 20}}>
                        <Grid container xs={3} justifyContent="center" alignItems="center">
                            <Grid>
                                Water Heater
                            </Grid>  
                        </Grid>
                        <Grid item xs={9}>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Age:</b> 28
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Estimated Life Span:</b> -10
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Last Work Order:</b> 03/02/2020
                            </Grid>
                            
                            <TableContainer sx={{ height: 100, overflow: 'auto' }} >
                                <Table aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontSize: 14, fontWeight: 500}}>Date</TableCell>
                                        <TableCell align="center" style={{fontSize: 14, fontWeight: 500}}>Notes</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {waterHeaterRows.map((row) => (
                                        <TableRow
                                        key={row.date}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {row.date}
                                        </TableCell>
                                        <TableCell align="center">{row.notes}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                        </Grid>
                    </Grid>

                    {/* Washer */}                       
                    <Grid container style={{paddingBottom: 10, borderBottom: 'dashed #313649 1px', marginBottom: 20}}>
                        <Grid container xs={3} justifyContent="center" alignItems="center">
                            <Grid>
                                Washer
                            </Grid>  
                        </Grid>
                        <Grid item xs={9}>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Age:</b> 10
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Estimated Life Span:</b> 3
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Last Work Order:</b> N/A
                            </Grid>
                            
                            <TableContainer sx={{ height: 100, overflow: 'auto' }} >
                                <Table aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontSize: 14, fontWeight: 500}}>Date</TableCell>
                                        <TableCell align="center" style={{fontSize: 14, fontWeight: 500}}>Notes</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {washerRows.map((row) => (
                                        <TableRow
                                        key={row.date}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {row.date}
                                        </TableCell>
                                        <TableCell align="center">{row.notes}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                        </Grid>
                    </Grid>


                    {/* Dryer */}                       
                    <Grid container style={{paddingBottom: 10,borderBottom: 'dashed #313649 1px', marginBottom: 20}}>
                        <Grid container xs={3} justifyContent="center" alignItems="center">
                            <Grid>
                                Dryer
                            </Grid>  
                        </Grid>
                        <Grid item xs={9}>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Age:</b> 3
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Estimated Life Span:</b> 13
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Last Work Order:</b> 10/10/2020
                            </Grid>
                            
                            <TableContainer sx={{ height: 100, overflow: 'auto' }} >
                                <Table aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontSize: 14, fontWeight: 500}}>Date</TableCell>
                                        <TableCell align="center" style={{fontSize: 14, fontWeight: 500}}>Notes</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {dryerRows.map((row) => (
                                        <TableRow
                                        key={row.date}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {row.date}
                                        </TableCell>
                                        <TableCell align="center">{row.notes}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                        </Grid>
                    </Grid>


                    {/* Refrigerator */}                       
                    <Grid container style={{paddingBottom: 10, borderBottom: 'dashed #313649 1px', marginBottom: 20}}>
                        <Grid container xs={3} justifyContent="center" alignItems="center">
                            <Grid>
                                Refrigerator
                            </Grid>  
                        </Grid>
                        <Grid item xs={9}>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Age:</b> 9
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Estimated Life Span:</b> 9
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Last Work Order:</b> 01/12/2021
                            </Grid>
                            
                            <TableContainer sx={{ height: 100, overflow: 'auto' }} >
                                <Table aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontSize: 14, fontWeight: 500}}>Date</TableCell>
                                        <TableCell align="center" style={{fontSize: 14, fontWeight: 500}}>Notes</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {refrigeratorRows.map((row) => (
                                        <TableRow
                                        key={row.date}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {row.date}
                                        </TableCell>
                                        <TableCell align="center">{row.notes}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                        </Grid>
                    </Grid>



                    {/* Dishwasher */}                       
                    <Grid container style={{paddingBottom: 10, borderBottom: 'dashed #313649 1px', marginBottom: 20}}>
                        <Grid container xs={3} justifyContent="center" alignItems="center">
                            <Grid>
                                Dishwasher
                            </Grid>  
                        </Grid>
                        <Grid item xs={9}>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Age:</b> 10
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Estimated Life Span:</b> 2
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Last Work Order:</b> N/A
                            </Grid>
                            
                            <TableContainer sx={{ height: 100, overflow: 'auto' }} >
                                <Table aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontSize: 14, fontWeight: 500}}>Date</TableCell>
                                        <TableCell align="center" style={{fontSize: 14, fontWeight: 500}}>Notes</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {dishwasherRows.map((row) => (
                                        <TableRow
                                        key={row.date}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {row.date}
                                        </TableCell>
                                        <TableCell align="center">{row.notes}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                        </Grid>
                    </Grid>



                    {/* Range */}                       
                    <Grid container style={{paddingBottom: 10, borderBottom: 'dashed #313649 1px', marginBottom: 20}}>
                        <Grid container xs={3} justifyContent="center" alignItems="center">
                            <Grid>
                                Range
                            </Grid>  
                        </Grid>
                        <Grid item xs={9}>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Age:</b> 8
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Estimated Life Span:</b> 4
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Last Work Order:</b> N/A
                            </Grid>
                            
                            <TableContainer sx={{ height: 100, overflow: 'auto' }} >
                                <Table aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontSize: 14, fontWeight: 500}}>Date</TableCell>
                                        <TableCell align="center" style={{fontSize: 14, fontWeight: 500}}>Notes</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {dishwasherRows.map((row) => (
                                        <TableRow
                                        key={row.date}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {row.date}
                                        </TableCell>
                                        <TableCell align="center">{row.notes}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                        </Grid>
                    </Grid>


                     {/* Garbage Disposal*/}                       
                     <Grid container style={{paddingBottom: 10, borderBottom: 'dashed #313649 1px', marginBottom: 20}}>
                        <Grid container xs={3} justifyContent="center" alignItems="center">
                            <Grid>
                                Garbage Disposal
                            </Grid>  
                        </Grid>
                        <Grid item xs={9}>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Age:</b> 3
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Estimated Life Span:</b> 13
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                                <b>Last Work Order:</b> N/A
                            </Grid>
                            
                            <TableContainer sx={{ height: 100, overflow: 'auto' }} >
                                <Table aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontSize: 14, fontWeight: 500}}>Date</TableCell>
                                        <TableCell align="center" style={{fontSize: 14, fontWeight: 500}}>Notes</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {dishwasherRows.map((row) => (
                                        <TableRow
                                        key={row.date}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {row.date}
                                        </TableCell>
                                        <TableCell align="center">{row.notes}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                        </Grid>
                    </Grid>


                    {/* Garbage Disposal*/}                       
                    <Grid container style={{paddingBottom: 10, borderBottom: 'dashed #313649 1px', marginBottom: 20}}>
                        <Grid container xs={3} justifyContent="center" alignItems="center">
                            <Grid>
                                Plumbing
                            </Grid>  
                        </Grid>
                        <Grid item xs={9}>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                             5 plumbing calls in the last 12 months
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                            2 clogged drains
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                            2 cartridge replacements.
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                            1 shower fixture replacement.
                            </Grid>
                        </Grid>
                    </Grid>


                    {/* Electrical */}                       
                    <Grid container style={{paddingBottom: 10, borderBottom: 'dashed #313649 1px', marginBottom: 20}}>
                        <Grid container xs={3} justifyContent="center" alignItems="center">
                            <Grid>
                                Electical
                            </Grid>  
                        </Grid>
                        <Grid item xs={9}>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                             N/A
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Other Maintenance */}                       
                    <Grid container style={{paddingBottom: 10, borderBottom: 'dashed #313649 1px', marginBottom: 20}}>
                        <Grid container xs={3} justifyContent="center" alignItems="center">
                            <Grid>
                                Other Maintenance
                            </Grid>  
                        </Grid>
                        <Grid item xs={9}>
                            <Grid item xs={12} style={{textAlign: 'left', fontSize: 16, fontWeight: 500, color: "#313649", paddingBottom: 7}}>
                            Weather stripping replaced on sliding glass door. 
                            </Grid>
                        </Grid>
                    </Grid>





                </Grid>

            </Grid>

          </Grid>

        </Box>
      </Modal>
    </div>
  );
}
